<!--
 * @Descripttion: 企业列表
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-07-06 16:02:44
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-08-19 15:00:40
-->
<template>
    <div class="accountManagement">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">企业数据</div>
        </div>
        <div class="case-wrap">
            <div class="title-wrap">
                <div class="search-wrap">
                    <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                    <el-button @click="clickSearch" type="primary">搜索</el-button>
                </div>
                <el-button v-if="user.kf_level == 1 || user.kf_level == 4" type="primary" @click="isAddAccount = true">创建</el-button>
            </div>
            
            <div class="table-wrap" v-loading="loading">
                <el-table :data="tableData" border style="width: 100%">
                    <el-table-column align="center" prop="id" label="ID" width="70"></el-table-column>
                    <el-table-column align="center" prop="name" label="企业名称" ></el-table-column>
                    <el-table-column align="center" label="logo" width="100">
                        <template slot-scope="scope">
                            <img :src="scope.row.logo" alt="" width="60">
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="province" label="地区" width="200" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{scope.row.province}}{{scope.row.city}}{{scope.row.street}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="address_name" label="详情地址" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="content" label="描述" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="enterprise_brand_count" label="子品牌数" width="120"></el-table-column>
                    <el-table-column align="center" prop="create_time" label="创建时间" width="180"></el-table-column>
                    <el-table-column align="center" label="操作" width="80" v-if="user.kf_level == 1  || user.kf_level == 4">
                        <template slot-scope="scope">
                            <div class="operation-wrap">
                                <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                                    <i @click="clickEdit(scope.row)" class="iconfont el-icon-edit"></i>
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                    <i @click="clickDel(scope.row)" class="iconfont el-icon-delete"></i>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="10"
                        layout="total, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog
            :before-close="handleClose" 
            :close-on-click-modal="false"
            :visible.sync="isAddAccount" 
            custom-class="dialog-wrap">
            <div v-if="editId<0" class="title">创建企业数据</div>
            <div v-else class="title">编辑企业数据</div>
            <div class="content-wrap" v-loading="addLoading">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="企业名称" prop="name">
                        <el-input v-model="ruleForm.name" placeholder="请输入企业名称"></el-input>
                    </el-form-item>
                    <el-form-item label="企业logo" prop="logo">
                        <div class="upload-wrap">
                            <aliyun-upload 
                                :limitSize="5" :limit="1" :isShopTip="false" v-model="image"
                                :btnDisplay="image.length<1" listType="picture-card">
                            </aliyun-upload>
                            <div v-if="image.length>0" class="fileList-box">
                                <div @click="delImageList" class="delFile">x</div>
                                <el-image fit="contain" class="pic"
                                    :preview-src-list="image" :src="image[0]"/>
                            </div>
                            <div>只能上传 jpg,jpeg,png,gif 格式文件，且不超过5M </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="选择省份" prop="province">
                        <el-select v-model="ruleForm.province" placeholder="请选择省份" @change="bindProvinceChange">
                            <el-option v-for="(item,index) in provinceList" :label="item.label" 
                                :value="item.value+'|'+item.label" :key="index">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择城市" prop="city">
                        <el-select v-model="ruleForm.city" placeholder="请选择城市" @change="bindCityChange">
                            <el-option v-for="(item,index) in cityList" :label="item.label" 
                                :value="item.value+'|'+item.label" :key="index">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择区/县" prop="street">
                        <el-select v-model="ruleForm.street" placeholder="请选择区/县" @change="bindCountyChange">
                            <el-option v-for="(item,index) in countyList" :label="item.label" 
                                :value="item.value+'|'+item.label" :key="index">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="详情地址" prop="address_name">
                        <el-input type="textarea" v-model="ruleForm.address_name" placeholder="请输入详情地址"></el-input>
                    </el-form-item>
                    <el-form-item label="描述" prop="content">
                        <el-input type="textarea" v-model="ruleForm.content" placeholder="请输入描述"></el-input>
                    </el-form-item>
                    <div class="btn-wrap">
                        <el-button v-if="editId<0" type="primary" @click="submitForm">立即创建</el-button>
                        <el-button v-else type="primary" @click="submitFormEdit">编辑</el-button>
                        <el-button @click="resetForm">重置</el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
const datas = require('@/utils/area-data.js');
import AliyunUpload from "@/components/upload/AliyunUpload.vue";
export default {
    components:{
        AliyunUpload,
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'enterpriseList',
            user: {},
            tableData: [],
            currentPage: 1,
            keyword: '',
            total: 0,
            loading: false,
            isAddAccount: false,
            editId: -1,
            btnLoading: false,
            addLoading: false,
            image: [],
            ruleForm: {
                name: '',
                logo: '',
                province: '',
                city: '',
                street: '',
                address_name: '',
                content: '',
            },
            rules: {
                name: [
                    { required: true, message: '请输入企业名称', trigger: 'blur' },
                ],
                // address_name: [
                //     { required: true, message: '请输入详情地址', trigger: 'blur' },
                // ],
                // province: [
                //     { required: true, message: '请选择省份', trigger: 'change' }
                // ],
                // city: [
                //     { required: true, message: '请选择城市', trigger: 'change' }
                // ],
                // street: [
                //     { required: true, message: '请选择区/县', trigger: 'change' }
                // ],
            },
            provinceList: [],
	        cityList: [],
	        countyList: []
        }
    },
    mounted () {
        this.provinceList = datas.areaList.provinceList;
        this.$route.meta.keepAlive = true
        this.user = JSON.parse(localStorage.getItem('userInfo'));
        this.getIndexList();
    },
    methods: {
        // 获取列表数据
        getIndexList(){
            let params = {
                keyword: this.keyword,
                page: this.currentPage
            }
            this.loading = true;
            common.connect("/customerservicev1/enterprise/index",params,(res)=>{
                this.tableData = res.data.list;
                this.total = res.data.count;
                this.loading = false;
            });
        },
        //删除添加海报图片
        delImageList(){
            this.image.splice(0, 1)
        },
        // 创建
        submitForm(){
            this.$refs.ruleForm.validate((valid) => {
            if (valid) {
                this.ruleForm.logo = this.image[0];
                this.addLoading = true
                common.connect("/customerservicev1/enterprise/store",this.ruleForm,(res)=>{
                    if(res.isSuccess == 1){
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.getIndexList();
                        this.isAddAccount = false;
                        this.addLoading = false;
                        this.image = [];
                        this.$refs.ruleForm.resetFields();
                    }else{
                        this.addLoading = false;
                    }
                });
            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
        // 编辑页
        clickEdit(row){
            this.editId = row.id;
            this.isAddAccount = true;
            this.addLoading = true;
            common.connect("/customerservicev1/enterprise/edit",{id: row.id},(res)=>{
                this.image[0] = res.data.logo;
                this.ruleForm = res.data;
                let province = ''
                let city = ''
                datas.areaList.provinceList.forEach(v => {
                    if(v.label == res.data.province){
                        province = v.value
                    }
                });
                datas.areaList.cityList.forEach(v => {
                    if(v.label == res.data.city){
                        city = v.value
                    }
                });
                this.cityList = this.addrInit(2, datas.areaList.cityList, province);
                this.countyList = this.addrInit(4, datas.areaList.countyList, city);
                this.addLoading = false;
            });
        },
        // 编辑
        submitFormEdit(){
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let params = {
                        id: this.editId,
                        name: this.ruleForm.name,
                        logo: this.image[0],
                        province: this.ruleForm.province,
                        city: this.ruleForm.city,
                        street: this.ruleForm.street,
                        address_name: this.ruleForm.address_name,
                        content: this.ruleForm.content,
                    }
                    this.addLoading = true
                    common.connect("/customerservicev1/enterprise/update",params,(res)=>{
                        if(res.isSuccess == 1){
                            this.$message({
                                message: res.msg,
                                type: 'success'
                            });
                            this.getIndexList();
                            this.isAddAccount = false;
                            this.addLoading = false;
                            this.editId = -1;
                            this.image = [];
                            this.$refs.ruleForm.resetFields();
                        }else{
                            this.addLoading = false;
                        }
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 删除
        clickDel(row){
            this.$confirm('此操作将永久删除该企业, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect("/customerservicev1/enterprise/delete",{id: row.id},(res)=>{
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.getIndexList();
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
       
        // 重置
        resetForm(){
            this.$refs.ruleForm.resetFields();
        },
        // 关闭添加弹窗
        handleClose(){
            this.isAddAccount = false;
            this.editId = -1;
            this.image = [];
            this.$refs.ruleForm.resetFields();
        },
        // 搜索
        clickSearch(){
            this.currentPage = 1;
            this.getIndexList();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getIndexList();
        },
        // 省
        bindProvinceChange(vals) {
            // 拿到省份对应的唯一编号
            let arr = vals.split('|')
            this.ruleForm.province = arr[1]
            this.ruleForm.city = '';
            this.ruleForm.street = '';
            // 拿到对应的城市
            this.cityList = this.addrInit(2, datas.areaList.cityList, arr[0]);
        },
        // 市
        bindCityChange(vals) {
            this.ruleForm.street = '';
            let arr = vals.split('|')
            this.ruleForm.city = arr[1]
            // 拿到对应的区县
            this.countyList = this.addrInit(4, datas.areaList.countyList, arr[0]);
        },
        // 区县
        bindCountyChange(vals) {
            let arr = vals.split('|')
            this.ruleForm.street = arr[1]
        },
        // 将对象转为数组
        transArray(obj) {
            let arr = [];
            for (let i in obj) {
            arr.push(obj[i]);
            }
            return arr;
        },
        /**
         * 封装方法-获取相对应的省市区
         * @param {number} num 截取的位数
         * @param {*} list 要查询的数组
         * @param {*} str 要截取的字符串
         * @returns
         */
        addrInit(num, list, str) {
            let strSub = str.substr(0, num);
            let arr = this.transArray(list);
            let newArr = arr.filter(function (val, index, arr) {
            let newStr = val.value.substr(0, num);
            return newStr == strSub;
            });
            return newArr;
        },
    },
}
</script>

<style lang='scss'>
.accountManagement{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
            width: 4px;
            height: 20px;
            background: #409EFF;
            border-radius: 10px;
        }
        .title{
            font-size: 18px;
            font-weight: bold;
            padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;
        .title-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .search-wrap{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding-bottom: 20px;
                .el-input{
                    width: 200px;
                    margin-right: 10px;
                }
            }
        }
        .table-wrap{
            .operation-wrap{
                display: flex;
                justify-content: center;
                align-items: center;
                .iconfont{
                    font-size: 16px;
                    padding: 0 8px;
                    cursor: pointer;
                }
            }
        }
        .pagination-wrap{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-top: 20px;
        }
    }

    .dialog-wrap{
        width: 700px;
        .title{
            font-size: 16px;
            color: #000;
            margin-left: 20px;
            margin-top: -5px;
        }
        .content-wrap{
            padding-top: 20px;
            .upload-wrap{
                padding-top: 2px;
                .fileList-box{
                    width: 100px;
                    height: auto;
                    position: relative;
                    .delFile{
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        cursor: pointer;
                            width: 20px;
                            height: 20px;
                            border-radius: 100%;
                            border: 1px #ddd solid;
                            background: rgba(100, 100, 100, 0.5);
                            color: #fff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            z-index: 99;
                    }
                    .pic{
                        width: 100%;
                    }
                }
            }
            .el-input{
                width: 560px;
            }
            .el-textarea{
                width: 560px;
            }
            .btn-wrap{
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 20px;
            }
        }
      
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
}
</style>

